<template>
	<div class="urlInfo">
		
		<div class="img-box" v-if="data.length > 0">
			<div v-for="img in data" :key="img" class="img">
				<el-image
            style="
									width: 100px;
									height: 100px;
									border: 1px solid #dddddd;
									margin: 5px;
								"
					:src="img"
					:preview-src-list="data"
				>
				</el-image>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			data: {
				type: Array,
				default: () => [],
			},
		},
	};
</script>

<style scoped lang="less">
	.urlInfo {
		.img-box {
			display: flex;
			flex-wrap: wrap;
			.img {
				margin-right: 10px;
			}
		}
	}
</style>